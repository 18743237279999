import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'oidc-react';
import {CONFIG} from "./config";
const oidcConfig = {
  onSignIn: () => {
    // Redirect?
  },
  authority: CONFIG.AUTH_SERVER,
  clientId: CONFIG.CLIENT_ID,
  redirectUri: CONFIG.REDIRECT_URI,
};



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider {...oidcConfig}>
      <App />
      </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
