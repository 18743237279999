export const CONFIG = {
  "RESPONSE_TYPE": process.env.REACT_APP_RESPONSE_TYPE,
  "SCOPE": process.env.REACT_APP_SCOPE,
  "REDIRECT_URI": process.env.REACT_APP_REDIRECT_URI,
  "CLIENT_ID": process.env.REACT_APP_CLIENT_ID,
  "CLIENT_SECRET": process.env.REACT_APP_CLIENT_SECRET,
  "GRANT_TYPE": process.env.REACT_APP_GRANT_TYPE,
  "BASIC_TOKEN": process.env.REACT_APP_BASIC_TOKEN,
  "AUTH_SERVER": process.env.REACT_APP_AUTH_SERVER
};
